import React, {useState} from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import HeroImage from "components/sections/hero";
import ListTags from "components/listTags";
import ArrowLeft from "../assets/svg/arrow-left.svg";
import { ProjectFrontMatter, SingleProjectFrontMatter } from "types/graphql";
import { Seo } from "components/seo";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ArrowRight from "assets/svg/arrow-right.svg";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper'

import 'swiper/css';
import 'swiper/css/pagination';

const ProjectDetails = ({pageContext, data} : ProjectFrontMatter) => {

  const {
    project: { frontmatter, body },
    heroImage: { edges: { [0] : { node : sectionsData}}},
    projects: { edges: relatedProjects },
  } = data

  const {
    index: indexProject
  } = pageContext;

  let beforeProjects = relatedProjects.slice(0, indexProject)
  let cleanedProjects = relatedProjects.slice(indexProject+1).slice(0,3)

  if(cleanedProjects.length < 3 ) {
    cleanedProjects = [...beforeProjects,...cleanedProjects].slice(-3)
  }

  let imageUrl = getImage(frontmatter.imageUrl)

  return (
    <React.Fragment>
      <Seo title={frontmatter.metaTitle as string} description={frontmatter.metaDescription} image={imageUrl} />
      <div className="container mt-10 relative ">
        <Link to="/projets" type="button">
          <div className="arrow-left bg-gray-100 rounded-full w-12 h-12 2xl:absolute 2xl:-left-16 2xl:top-0">
            <ArrowLeft className="p-3 fill-black w-full" />
          </div>
        </Link>
        <div className="flex flex-col items-center">
          <p className="caption2 text-gray-600 uppercase mb-4 transition-text 3xl:text-h3" data-aos="custom" >
            <span className="transition-content">
              {frontmatter.title}
            </span>
            <span className="transition-layer"/>
          </p>
          <span className="block h1 text-center mb-6">
            <h4 className="fade" data-aos="custom">
              {frontmatter.description}
            </h4>
          </span>
          <ul className="flex flex-wrap gap-2 overflow-hidden" >
            <ListTags tags={[...frontmatter.tags, ...frontmatter.sectors]} />
          </ul>
          {imageUrl
            ?
            <figure className="overflow-hidden border-4 border-white rounded-xl shadow-2 mt-10 mb-8 lg:my-16 w-full transition-image z-1" data-aos="custom">
              <GatsbyImage
                image={imageUrl}
                alt={frontmatter.imageUrl_alt}
                className="h-64 lg:h-project-detail	object-cover w-full"
              />
              <span className="transition-layer" style={{transitionDelay: '0.5s'}}/>
            </figure>
            :
            <div className="my-6 lg:my-10"></div>
          }
        </div>
      </div>
      <div className="project-content">
        <MDXRenderer frontmatter={frontmatter}>{body}</MDXRenderer>
      </div>
      <div className="container w-full mb-10 border-gray-100 border-t">
        <span className="block caption2 text-center mt-6 text-gray-600 uppercase 3xl:text-h3">
          Nos autres projets
        </span>
        <Swiper
          speed={1000}
          autoHeight
          modules={[ Pagination ]}
          pagination={{
            el: '.pagination',
            type: 'bullets',
            bulletActiveClass: 'bg-gray-900 scale-200',
            bulletClass: 'bg-gray-500 rounded-full w-1.5 h-1.5 inline-block mx-3 cursor-pointer',
            clickable: true
          }}
        >
          {
            cleanedProjects
              .map(({ node: { frontmatter: project } }: SingleProjectFrontMatter, index: number) => {
                let projectImgUrl = getImage(project.imageUrl)
                return (
                  <SwiperSlide key={index}>
                    <Link to={`/projets/${project.slug}`}>
                      <div className="grid grid-cols-1 lg:grid-cols-2 pb-10 pt-10 lg:pt-20 px-3 gap-10 relative select-none	">
                        <div className="flex justify-center flex-col">
                          <span className="block caption2 text-gray-600 uppercase mb-4">
                            {project.title}
                          </span>
                          <h2 className="mb-5">
                            {project.description}
                          </h2>
                          <ul className="flex flex-wrap gap-2 overflow-hidden">
                            <ListTags tags={[...project.tags, ...project.sectors]} />
                          </ul>
                        </div>
                        <div>
                          <div className="bg-yellow arrow-right rounded-full w-16 h-16 mt-2 absolute lg:right-3 bottom-4 lg:bottom-2 block right-8 z-10 transform-none cursor-pointer">
                            <ArrowRight className="p-4 w-full" />
                          </div>
                          {projectImgUrl
                            ? <figure className="overflow-hidden border-4 border-white rounded-xl shadow-2 self-start w-full relative z-1 ">
                                <GatsbyImage
                                  image={projectImgUrl}
                                  alt="image"
                                  className="object-cover w-full h-[380px]"
                                />
                              </figure>
                            : <div></div>
                          }
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                )}
              )
          }
        </Swiper>
        <div className="pagination mt-16 mb-28 text-center" />
      </div>

      <HeroImage
        title={sectionsData.frontmatter.title}
        content={sectionsData.html}
        withTransition={false}
        buttonlabel={sectionsData.frontmatter.buttonlabel}
        imageUrl={sectionsData.frontmatter.imageUrl}
      />
    </React.Fragment>
  );
}

export const query = graphql`
  query GetSingleProject($slug: String) {
    heroImage : allMarkdownRemark(
        filter: { frontmatter: { format: { in: ["heroproject"] } } }
      ) {
        edges {
          node {
            html
            frontmatter {
              format
              title
              imageUrl
              buttonlabel
            }
          }
        }
      }
      
  projects: allMdx(
        filter: { fileAbsolutePath: { regex: "/(/projects/)/" } }
        sort: { order: ASC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            body
            frontmatter {
              slug
              title
              description
              imageUrl {
                childImageSharp {
                  gatsbyImageData(
                    width: 977
                  )
                }
              }
              imageBg {
                childImageSharp {
                  gatsbyImageData(
                    width: 591
                  )
                }
              }
              sectors
              tags
              testimonialName
              testimonialRole
              metaTitle
              metaDescription
              metaImage
            }
          }
        }
      }
      
  project: mdx(frontmatter: {slug: {eq: $slug }}) {
      body
      frontmatter {
        slug
        title
        description
        imageUrl {
          childImageSharp {
            gatsbyImageData(
              width: 977
          )
          }
        }
        imageUrl_alt
        imageBg {
          childImageSharp {
            gatsbyImageData(
              width: 591
          )
          }
        }
        imageBg_alt
        imageSolution {
          childImageSharp {
            gatsbyImageData(
              width: 463
          )
          }
        }
        imageSolution_alt
        sectors
        tags
        testimonialName
        testimonialRole
        achievements
        metaTitle
        metaDescription
        metaImage
      }
    }
  }
  `

export default  ProjectDetails;
